import styled from 'styled-components';

const StyledDisplay = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Alarm Clock';
  font-size: 30px;
`;

export default StyledDisplay;
