const levels = {
  easy: {
    width: 9,
    height: 9,
    bugs: 10,
  },
  normal: {
    width: 16,
    height: 16,
    bugs: 40,
  },
  hard: {
    width: 16,
    height: 30,
    bugs: 99,
  },
};

export default levels;
