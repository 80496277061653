import React, { useState, useCallback, useEffect } from 'react';
import { createGrid } from '../gameHelpers';
import levels from '../levels';

// Components
import GameBoard from './GameBoard';
import GameDisplay from './GameDisplay';
import NewGame from './NewGame';
import Settings from './Settings';
import HowTo from './HowTo';
import FlagToggle from './FlagToggle';

// Styled Components
import { StyledGameWrapper, StyledGame, StyledMain } from './styles/StyledGame';

// Custom Hooks
import useGrid from '../hooks/useGrid';
import useGameStatus from '../hooks/useGameStatus';

const BugSweeper = () => {
  const [game, setGame] = useState(levels.easy);
  const [started, startGame, stopGame, elapsedTime, resetGame] = useGameStatus();
  const [grid, setGrid, clearGrid, clearedTiles, setClearedTiles, lost, setLost] = useGrid(game);
  const [gameMode, setGameMode] = useState('playing');
  const [flags, setFlags] = useState(0);
  const [gameStatus, setGameStatus] = useState('Playing');
  const [emoji, setEmoji] = useState('😀');
  const [flagMode, setFlagMode] = useState(false);

  useEffect(() => {
    document.getElementById('wrapper').focus();
  }, []);

  useEffect(() => {
    const nonMineTiles = (game.width * game.height) - game.bugs;

    if (clearedTiles === nonMineTiles) {
      setGameStatus('You win!');
      stopGame();
      setGameMode('win');
    }
    if (lost === true) {
      setGameStatus('You lose!');
      stopGame();
      setGameMode('lose');
    }
  }, [clearedTiles, game.height, game.bugs, game.width, flags, lost, stopGame]);

  useEffect(() => {
    if (gameMode === 'lose') {
      setEmoji('😵');
    } else if (gameMode === 'win') {
      setEmoji('😎');
    } else {
      setEmoji('😀');
    }
  }, [gameMode]);

  const newGame = () => {
    setGrid(createGrid(game.height, game.width, game.bugs));
    setGameStatus('');
    setGameMode('playing');
    setFlags(0);
    setLost(false);
    setClearedTiles(0);
    resetGame();
  };

  const addFlag = (xAxis, yAxis, newGrid) => {
    const updatedGrid = [...newGrid];
    if (updatedGrid[yAxis][xAxis].flagged === false) {
      updatedGrid[yAxis][xAxis] = { ...updatedGrid[yAxis][xAxis], flagged: true };
      setFlags((prev) => {
        const total = prev + 1;
        return total;
      });
      if (updatedGrid[yAxis][xAxis].unsure === true) {
        updatedGrid[yAxis][xAxis] = { ...updatedGrid[yAxis][xAxis], unsure: false, flagged: false };
      }
    } else {
      updatedGrid[yAxis][xAxis] = { ...updatedGrid[yAxis][xAxis], flagged: false, unsure: true };
      setFlags((prev) => {
        const total = prev - 1;
        return total;
      });
    }
    return updatedGrid;
  };

  const changeGame = (e) => {
    if (e.type === 'change') {
      setGame({ ...game, [e.target.name]: Number(e.target.value) });
    } else {
      setGame(levels[e.target.id]);
    }
  };

  const clearTiles = useCallback((x, y) => {
    clearGrid(x, y);
  }, []);

  const flagTile = useCallback((x, y) => {
    setGrid((prev) => addFlag(x, y, prev));
  }, [setGrid]);

  const switchFlagMode = () => {
    setFlagMode(!flagMode);
  };

  const keyDown = ({ keyCode }) => {
    if (keyCode === 18) {
      setFlagMode(true);
    }
  };

  const keyUp = ({ keyCode }) => {
    if (keyCode === 18) {
      setFlagMode(false);
    }
  };

  return (
    <StyledGameWrapper
      role="button"
      tabIndex="0"
      onKeyDown={(e) => keyDown(e)}
      onKeyUp={keyUp}
      id="wrapper"
    >
      <StyledGame>
        <GameDisplay flags={flags} bugs={game.bugs} elapsedTime={elapsedTime} emoji={emoji} />
        <FlagToggle flagMode={flagMode} switchFlagMode={switchFlagMode} />
        <div style={{ height: '25px', margin: '10px 0' }}>{gameStatus === 'Playing' ? '' : gameStatus}</div>
        <NewGame newGame={newGame} />
        <StyledMain>
          <HowTo />
          <GameBoard
            started={started}
            startGame={startGame}
            game={game}
            grid={grid}
            clearTiles={clearTiles}
            gameMode={gameMode}
            flagTile={flagTile}
            flagMode={flagMode}
          />
          <Settings changeGame={changeGame} game={game} newGame={newGame} gameMode={gameMode} />
        </StyledMain>
      </StyledGame>
    </StyledGameWrapper>
  );
};

export default BugSweeper;
