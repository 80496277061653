import { useState, useEffect } from 'react';

const Timer = (started) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    let interval = null;
    if (started) {
      interval = setInterval(() => {
        if (elapsedTime < 1000) {
          setElapsedTime((prev) => {
            const total = prev + 1;
            return total;
          });
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [elapsedTime, started]);

  const resetTime = () => {
    setElapsedTime(0);
  };

  return [elapsedTime, resetTime];
};

export default Timer;
