import React from 'react';
import PropTypes from 'prop-types';
import StyledFlagToggle from './styles/StyledFlagToggle';
// import homeGrey from './icons/home-grey.svg';
// import home from './icons/home.svg';

const FlagToggle = ({ flagMode, switchFlagMode }) => (
  <StyledFlagToggle>
    <label className="switch" htmlFor="switch">
      <input type="checkbox" id="switch" checked={flagMode} onChange={() => switchFlagMode()} />
      <span className="slider round" />
    </label>
  </StyledFlagToggle>
);

FlagToggle.propTypes = {
  switchFlagMode: PropTypes.func.isRequired,
  flagMode: PropTypes.bool.isRequired,
};

export default FlagToggle;
