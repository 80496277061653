import styled from 'styled-components';

const StyledHowTo = styled.div`
  display: flex;
  flex-direction: column;
  width:  20%;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export default StyledHowTo;
