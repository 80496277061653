import React from 'react';
import PropTypes from 'prop-types';
import StyledGrid from './styles/StyledGrid';
import GameCell from './GameCell';

const GameBoard = ({
  game, grid, clearTiles, gameMode, flagTile, started, startGame, flagMode,
}) => (
  <StyledGrid width={game.width} height={game.height}>
    {grid.map((row, y) => row.map((cell, x) => (
      <GameCell
        clearTiles={clearTiles}
          // eslint-disable-next-line react/no-array-index-key
        key={x}
        y={y}
        x={x}
        cell={cell}
        gameMode={gameMode}
        flagTile={flagTile}
        started={started}
        startGame={startGame}
        flagMode={flagMode}
      />
    )))}
  </StyledGrid>
);

GameBoard.propTypes = {
  game: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  grid: PropTypes.arrayOf(PropTypes.array).isRequired,
  clearTiles: PropTypes.func.isRequired,
  flagTile: PropTypes.func.isRequired,
  gameMode: PropTypes.string.isRequired,
  startGame: PropTypes.func.isRequired,
  started: PropTypes.bool.isRequired,
  flagMode: PropTypes.bool.isRequired,
};

export default GameBoard;
