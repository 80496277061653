import styled from 'styled-components';

export const StyledSettings = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
  `;

export const StyledLevel = styled.div`
  border: solid;
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  background-color: ${(props) => (props.id === props.level ? 'red' : 'white')}
`;
