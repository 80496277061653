import styled from 'styled-components';

const StyledGrid = styled.div`
  display: grid;
  touch-action: manipulation;
  grid-template-rows: repeat(
    ${(props) => props.height},
    calc((3vw * ${(props) => props.width}) / ${(props) => props.width})
  );
  grid-template-columns: repeat(${(props) => props.width}, 1fr);
  border: 2px solid #333;
  width 100%;
  max-width: calc(3vw * ${(props) => props.width});
  margin-top: 50px;
  @media (max-width: 800px) {
    grid-template-rows: repeat(
      ${(props) => props.height},
      calc((10vw * ${(props) => props.width}) / ${(props) => props.width})
    );
    max-width: calc(10vw * ${(props) => props.width});
  }
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
`;

export default StyledGrid;
