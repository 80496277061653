import styled from 'styled-components';

export const StyledGameWrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const StyledGame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledMain = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;
