import React from 'react';
import PropTypes from 'prop-types';
import StyledDisplay from './styles/StyledDisplay';
import { pad } from '../gameHelpers';

const GameDisplay = ({
  flags, bugs, elapsedTime, emoji,
}) => (
  <StyledDisplay>
    <div>{bugs - flags < 0 ? '000' : pad(bugs - flags, 3)}</div>
    <div>{emoji}</div>
    <div>
      {pad(elapsedTime, 3)}
    </div>
  </StyledDisplay>
);

GameDisplay.propTypes = {
  bugs: PropTypes.number.isRequired,
  flags: PropTypes.number.isRequired,
  elapsedTime: PropTypes.number.isRequired,
  emoji: PropTypes.string.isRequired,
};

export default GameDisplay;
