/* eslint-disable max-len */
import React from 'react';
import StyledHowTo from './styles/StyledHowTo';

const HowToPlay = () => (
  <StyledHowTo>
    <h2>How to Play:</h2>
    <ul>
      <li>Click on the game tiles to test for coronavirus. The tiles will either be clear or they&apos;ll indicate how many nearby outbreaks there are.</li>
      <li>Use the number of cases you&apos;ve found to narrow down on the coronavirus outbreaks. When you&apos;ve found an outbreak, use option + click to set up mask usage guidelines</li>
      <li>The game ends when all outbreak areas have mask guidelines</li>
    </ul>
  </StyledHowTo>
);

export default HowToPlay;
