import styled from 'styled-components';

import testIcon from '../icons/swab.png';
import maskIcon from '../icons/medical-mask.png';
import homeIcon from '../icons/home.png';

const StyledCell = styled.div`
  width: auto;
  background: ${(props) => (props.cleared ? 'white' : 'grey')};
  border: 1px solid #333;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  img {
    max-width: 85%;
  }
  .flagged {
    display: ${(props) => ((props.flagged && props.gameMode !== 'lose') || (props.bug && props.gameMode === 'win') ? 'flex' : 'none')}
  }
  .wrong {
    display: ${(props) => (props.flagged && props.bug === false && props.gameMode === 'lose' ? 'flex' : 'none')}
  }
  .bug {
    display: ${(props) => (props.bug === true && props.cleared === true ? 'flex' : 'none')}
  }
  .unsure {
    display: ${(props) => (props.unsure ? 'flex' : 'none')}
  }
  .adjacent {
    display: ${(props) => (props.adjacent > 0
      && props.cleared === true
      && props.bug === false
      && props.flagged === false ? 'flex' : 'none')}
  }
  cursor: url(${(props) => {
    if (props.gameMode === 'playing') {
      if (props.flagMode === false && props.cleared === false) {
        return testIcon;
      } if (props.flagMode === true
        && props.cleared === false
        && props.flagged === false
        && props.unsure === false) {
        return homeIcon;
      } if (props.flagMode === true && props.cleared === false && props.flagged === true) {
        return maskIcon;
      }
      if (props.flagMode === true && props.cleared === false && props.unsure === true) {
        return maskIcon;
      }
    }
    return 'default';
  }}), default;
`;

export default StyledCell;
