export const checkValid = (y, x, grid) => {
  if (grid[y] && grid[y][x] && grid[y][x].bug === false) {
    return true;
  }
  return false;
};

export const createGrid = (height, width, bugs) => {
  const grid = Array.from(Array(height), () => new Array(width).fill({
    bug: false,
    adjacent: 0,
    flagged: false,
    cleared: false,
    unsure: false,
  }));

  let bugsPlaced = 0;
  while (bugsPlaced < bugs) {
    const x = Math.floor(Math.random() * width);
    const y = Math.floor(Math.random() * height);

    if (grid[y][x].bug === false) {
      bugsPlaced += 1;
      grid[y][x] = { ...grid[y][x], bug: true };

      const directions = [
        [y - 1, x - 1],
        [y - 1, x],
        [y - 1, x + 1],
        [y, x - 1],
        [y, x + 1],
        [y + 1, x - 1],
        [y + 1, x],
        [y + 1, x + 1],
      ];

      for (let i = 0; i < directions.length; i += 1) {
        const yAxis = directions[i][0];
        const xAxis = directions[i][1];

        if (checkValid(yAxis, xAxis, grid)) {
          grid[yAxis][xAxis] = {
            ...grid[yAxis][xAxis],
            adjacent: grid[yAxis][xAxis].adjacent + 1,
          };
        }
      }
    }
  }
  return grid;
};

// export const sweepCells = (newGrid) => newGrid.reduce((acc, row) => {
//   if (row.findIndex((cell) => cell.cleared === true) === -1) {
//     // setRowsCleared((prev) => (prev += 1));
//     // acc.unshift(new Array(newGrid[0].length).fill([0, 'clear']));
//     return acc += 1;
//   }
//   // acc.push(row);
//   return acc;
// }, 0);

export const addFlag = (xAxis, yAxis, newGrid) => {
  const updatedGrid = [...newGrid];

  if (updatedGrid[yAxis][xAxis].flagged === false) {
    updatedGrid[yAxis][xAxis] = { ...updatedGrid[yAxis][xAxis], flagged: true };
  } else {
    updatedGrid[yAxis][xAxis] = { ...updatedGrid[yAxis][xAxis], flagged: false };
  }
  return updatedGrid;
};

// export const floodFill = (xAxis, yAxis, newGrid) => {
//   let updatedGrid = [...newGrid];

//   if (updatedGrid[yAxis]
//     && updatedGrid[yAxis][xAxis]
//     && updatedGrid[yAxis][xAxis].cleared === false) {
//     if (updatedGrid[yAxis][xAxis].bug === true) {
//       updatedGrid = newGrid.map((row) => row.map((cell) => {
//         if (cell.bug === true) {
//           return { ...cell, cleared: true };
//         } return cell;
//       }));
//       return updatedGrid;
//     }
//     if (updatedGrid[yAxis][xAxis].adjacent > 0) {
//       updatedGrid[yAxis][xAxis] = { ...updatedGrid[yAxis][xAxis], cleared: true };
//       return updatedGrid;
//     }
//     updatedGrid[yAxis][xAxis] = { ...updatedGrid[yAxis][xAxis], cleared: true };
//     floodFill(xAxis - 1, yAxis - 1, updatedGrid);
//     floodFill(xAxis - 1, yAxis, updatedGrid);
//     floodFill(xAxis - 1, yAxis + 1, updatedGrid);
//     floodFill(xAxis, yAxis - 1, updatedGrid);
//     floodFill(xAxis, yAxis + 1, updatedGrid);
//     floodFill(xAxis + 1, yAxis - 1, updatedGrid);
//     floodFill(xAxis + 1, yAxis, updatedGrid);
//     floodFill(xAxis + 1, yAxis + 1, updatedGrid);
//   }
//   return updatedGrid;
// };

export const pad = (num, size) => {
  let s = `${num}`;
  while (s.length < size) s = `0${s}`;
  return s;
};
