import { createGlobalStyle } from 'styled-components';

import AlarmClock from './alarm-clock.ttf';

export default createGlobalStyle`
    @font-face {
        font-family: 'Alarm Clock';
        src: url(${AlarmClock});
        font-weight: 300;
        font-style: normal;
    }
`;
