import React from 'react';
import BugSweeper from './components/BugSweeper';
import GlobalFonts from './fonts/fonts';
import './App.css';

function App() {
  return (
    <div className="App">
      <GlobalFonts />
      <BugSweeper />
    </div>
  );
}

export default App;
