import React from 'react';
import PropTypes from 'prop-types';
import StyledCell from './styles/StyledCell';
import virus from './icons/coronavirus.svg';
import mask from './icons/medical-mask.svg';
import home from './icons/home.svg';
import close from './icons/close.svg';

const GameCell = ({
  cell, x, y, clearTiles, gameMode, flagTile, started, startGame, flagMode,
}) => {
  const {
    bug, cleared, flagged, adjacent, unsure,
  } = cell;

  const handleClick = () => {
    if (started === false && gameMode !== 'win' && gameMode !== 'lose') {
      startGame();
    }
    if (flagMode === false && flagged === false && cleared === false && gameMode === 'playing') {
      clearTiles(x, y);
    }
    if (flagMode === true && cleared === false) {
      flagTile(x, y);
    }
  };

  return (
    <StyledCell
      onClick={handleClick}
      cleared={cleared}
      flagMode={flagMode}
      flagged={flagged}
      unsure={unsure}
      gameMode={gameMode}
      bug={bug}
      adjacent={adjacent}
    >
      <img src={virus} alt="coronavirus icon" className="bug" />
      <span className="adjacent">{adjacent}</span>
      <img src={home} alt="house icon" className="flagged" />
      <img src={close} alt="wrong bug" className="wrong" />
      <img src={mask} className="unsure" alt="mask icon" />
    </StyledCell>
  );
};

GameCell.propTypes = {
  cell: PropTypes.shape({
    bug: PropTypes.bool.isRequired,
    adjacent: PropTypes.number.isRequired,
    cleared: PropTypes.bool.isRequired,
    flagged: PropTypes.bool.isRequired,
    unsure: PropTypes.bool.isRequired,
  }).isRequired,
  clearTiles: PropTypes.func.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  flagTile: PropTypes.func.isRequired,
  gameMode: PropTypes.string.isRequired,
  startGame: PropTypes.func.isRequired,
  started: PropTypes.bool.isRequired,
  flagMode: PropTypes.bool.isRequired,
};

export default React.memo(GameCell);
