import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyledForm, StyledSettings, StyledLevel } from './styles/StyledSettings';

const Settings = ({
  changeGame, game, gameMode, newGame,
}) => {
  const [level, setLevel] = useState('easy');

  const handleClick = (e) => {
    if (gameMode !== 'playing') {
      newGame();
    }
    if (e.target.id === 'easy' || e.target.id === 'normal' || e.target.id === 'hard') {
      changeGame(e);
      setLevel(e.target.id);
    } else {
      setLevel('custom');
    }
  };

  return (
    <StyledSettings>
      <StyledLevel id="easy" role="button" onClick={handleClick} tabIndex={0} level={level}>
        Easy - 9x9 / 10 Outbreaks
      </StyledLevel>
      <StyledLevel id="normal" role="button" onClick={handleClick} tabIndex={0} level={level}>
        Normal - 16x16 / 40 Outbreaks
      </StyledLevel>
      <StyledLevel id="hard" role="button" onClick={handleClick} tabIndex={0} level={level}>
        Hard - 16x30 / 99 Outbreaks
      </StyledLevel>
      <StyledLevel level={level} id="custom" onMouseDown={handleClick}>
        <StyledForm>
          {`Custom - ${game.width}x${game.height} / ${game.bugs} Outbreaks`}
          <label htmlFor="width">
            Width:
            <input type="range" name="width" id="width" onChange={changeGame} value={game.width} min="2" max="50" />
          </label>
          <label htmlFor="height">
            Height:
            <input type="range" name="height" id="height" onChange={changeGame} value={game.height} min="2" max="50" />
          </label>
          <label htmlFor="bugs">
            Viruses:
            <input type="range" name="bugs" id="bugs" min="0" max="90" onChange={changeGame} value={game.bugs} />
          </label>
        </StyledForm>
      </StyledLevel>
    </StyledSettings>
  );
};

Settings.propTypes = {
  game: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    bugs: PropTypes.number.isRequired,
  }).isRequired,
  changeGame: PropTypes.func.isRequired,
  newGame: PropTypes.func.isRequired,
  gameMode: PropTypes.string.isRequired,
};

export default Settings;
