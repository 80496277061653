import { useState } from 'react';
import useTimer from './useTimer';

const useGameStatus = () => {
  const [started, setStarted] = useState(false);
  const [elapsedTime, resetTime] = useTimer(started);

  const startGame = () => {
    setStarted(true);
  };

  const stopGame = () => {
    setStarted(false);
  };

  const resetGame = () => {
    setStarted(false);
    resetTime();
  };

  return [started, startGame, stopGame, elapsedTime, resetGame];
};

export default useGameStatus;
