import React from 'react';
import PropTypes from 'prop-types';

const NewGame = ({ newGame }) => (
  <button type="button" onClick={newGame}>New Game</button>
);

NewGame.propTypes = {
  newGame: PropTypes.func.isRequired,
};

export default NewGame;
