import {
  useState,
  useEffect,
} from 'react';
import { createGrid } from '../gameHelpers';

const useGrid = (game) => {
  const [grid, setGrid] = useState(createGrid(game.height, game.width, game.bugs));
  const [clearedTiles, setClearedTiles] = useState(0);
  const [lost, setLost] = useState(false);

  useEffect(() => {
    setGrid(createGrid(game.height, game.width, game.bugs));
  }, [game.height, game.bugs, game.width]);

  const clearGrid = (xAxis, yAxis) => {
    const floodFill = (x, y, prevGrid) => {
      let updatedGrid = [...prevGrid];

      if (updatedGrid[y]
    && updatedGrid[y][x]
    && updatedGrid[y][x].cleared === false) {
        if (updatedGrid[y][x].bug === true) {
          updatedGrid = prevGrid.map((row) => row.map((cell) => {
            if (cell.bug === true || (cell.flagged === true && cell.bug === false)) {
              setLost(true);
              return { ...cell, cleared: true, unsure: false };
            } return cell;
          }));
          return updatedGrid;
        }
        if (updatedGrid[y][x].adjacent > 0) {
          updatedGrid[y][x] = { ...updatedGrid[y][x], cleared: true, unsure: false };
          setClearedTiles((prev) => {
            const total = prev + 1;
            return total;
          });
          return updatedGrid;
        }
        updatedGrid[y][x] = { ...updatedGrid[y][x], cleared: true, unsure: false };
        setClearedTiles((prev) => {
          const total = prev + 1;
          return total;
        });
        floodFill(x - 1, y - 1, updatedGrid);
        floodFill(x - 1, y, updatedGrid);
        floodFill(x - 1, y + 1, updatedGrid);
        floodFill(x, y - 1, updatedGrid);
        floodFill(x, y + 1, updatedGrid);
        floodFill(x + 1, y - 1, updatedGrid);
        floodFill(x + 1, y, updatedGrid);
        floodFill(x + 1, y + 1, updatedGrid);
      }
      return updatedGrid;
    };
    setGrid((prev) => floodFill(xAxis, yAxis, prev));
  };
  return [grid, setGrid, clearGrid, clearedTiles, setClearedTiles, lost, setLost];
};

export default useGrid;
